<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <!-- Verification Card -->
        <div class="card shadow">
          <div v-if="user.loggedIn">
            <div class="card-header">Welcome, {{ user.data.email }}!</div>
            <div class="card-body center-text">
              <div class="alert alert-success" role="alert">
                <p>
                  <br /><span v-if="userVerified" style="color: green"
                    >Your API key is
                    <span class="api-key">{{ user.data.uid }}</span>
                    <br />Please store your key in a safe place.</span
                  ><br />
                  <span v-if="userVerified" style="color: green"
                    >Your email address ({{ user.data.email }}) has been
                    verified!</span
                  >
                  <span class="p-20" v-else
                    >Your email address ({{ user.data.email }}) has NOT been
                    verified.<br />Please check your email for the verification
                    message. <br />Your API Key will appear here after you have
                    verified your email address and logged in.</span
                  >
                </p>
                <div
                  class="my-4 d-flex flex-column align-items-center justify-content-evenly"
                >
                  <button
                    v-if="!userVerified"
                    @click.prevent="resendVerificationEmail"
                    class="btn btn-primary"
                  >
                    Resend Verification Email
                  </button>
                  <button @click.prevent="signOut" class="btn btn-primary mt-4">
                    Log Out
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="alert alert-danger" role="alert">
            You are not logged in!
          </div>
        </div>
        <!-- End of Verification Card -->
        <!-- Admin Card -->
        <div class="card mt-4 shadow">
          <div
            class="card-header d-flex gap-0 align-content-center justify-content-center"
          >
            <div class="me-2 fw-bold">Amt. devices online</div>
            <div class="form-check form-check-inline p-0 m-0 text-center">
              <div class="form-check form-switch form-check-inline m-0">
                <input
                  type="checkbox"
                  name="inlineCheckbox1"
                  id=""
                  class="form-check-input align-self-center"
                  v-model="amtOrPercentage"
                />
              </div>
            </div>
            <div class="fw-bold">% devices online</div>
          </div>
          <div class="card-body">
            <div
              v-if="!amtOrPercentage"
              class="d-grid d-flex flex-column align-items-center"
            >
              <p class="text-center">
                Total connected devices:
                <span class="fw-bold">{{ boardsLength }}</span> <br />
                Minimum engagement threshold:
                <span class="fw-bold"
                  >{{ minEngagement }}/{{ boardsLength }}</span
                >
                devices
                <input
                  min="1"
                  :max="boardsLength"
                  step="1"
                  type="range"
                  name="range"
                  class="form-range mt-3"
                  v-model="minEngagement"
                />
              </p>
            </div>

            <div v-else class="d-grid d-flex flex-column align-items-center">
              <p class="text-center">
                Total connected devices:
                <span class="fw-bold">{{ boardsLength }}</span> <br />
                Minimum engagement threshold:
                <span class="fw-bold">{{ percentage }}%</span> of devices
                <input
                  min="0"
                  max="100"
                  step="1"
                  type="range"
                  name="percent"
                  class="form-range mt-3"
                  v-model="percentage"
                />
              </p>
            </div>
            <div class="d-grid d-flex flex-column align-items-center">
              <button
                class="btn btn-success rounded-pill mt-1 px-4 shadow"
                @click="signalSignal"
                :disabled="signalSent"
              >
                SIGNAL
              </button>

              <p class="my-3" v-if="!amtOrPercentage">
                Total engagement:
                <span class="fw-bold">{{ activeBoards }}</span> of
                <span class="fw-bold">{{ boardsLength }}</span> devices
              </p>
              <p v-else class="my-3">
                Total engagement:
                <span class="fw-bold">{{ percentActive }}</span> % of devices
              </p>

              <button
                v-if="!amtOrPercentage"
                class="btn btn-success rounded-pill px-4 shadow shadow-sm"
                :disabled="!thresholdMet"
                @click="triggerTrigger"
              >
                TRIGGER
              </button>
              <button
                v-else
                class="btn btn-success rounded-pill px-4 shadow shadow-sm"
                :disabled="!percentageMet"
                @click="triggerTrigger"
              >
                TRIGGER
              </button>
            </div>
          </div>
        </div>
        <!-- End of Admin Card -->
      </div>
    </div>
    <!-- Board Data Table -->
    <div class="row mt-4">
      <div class="container">
        <div class="col-xs-12">
          <div class="table-responsive-sm">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Board ID:</th>
                  <th>Action:</th>
                  <th>Email:</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(board, index, index2) in boardData" :key="index">
                  <th>{{ index2 + 1 }}</th>
                  <td>
                    <p>{{ index }}</p>
                  </td>
                  <td :class="board.action">
                    <p>{{ board.action }}</p>
                  </td>
                  <td>
                    <p>{{ board.email }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- End of Board Data Table -->
  </div>
  <footer class="text-center text-lg-start text-muted">
    <!-- Section: Social media -->
    <div class="container">
      <section
        class="d-flex justify-content-center justify-content-lg-between p-4"
      >
        <!-- Copyright -->
        <div
          class="text-center p-4 col-12"
          style="background-color: rgba(0, 0, 0, 0.05)"
        >
          © 2023 Copyright:
          <a class="text-reset fw-bold" href="https://buddydevices.com/"
            >buddydevices.com</a
          >
        </div>
        <!-- Copyright -->
      </section>
    </div>
  </footer>
</template>

<style scoped>
.off {
  background-color: red;
  color: yellow;
}

.on {
  background-color: green;
  color: white;
}

.api-key {
  white-space: nowrap;
  padding: 5px;
  font-size: 1.1rem;
  color: red;
  border: 1px dashed green;
  border-radius: 3px;

  @media screen and (max-width: 500px) {
    font-size: 1rem;
  }
}

.card-body p {
  line-height: 1.6rem;
}

.card-body.center-text {
  text-align: center;
}

button {
  transition: all 0.3s ease-in-out;
}
</style>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed } from "vue";
import { auth } from "../firebaseConfig";
import { sendEmailVerification } from "firebase/auth";

import { getDatabase, ref, set, onValue } from "firebase/database";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDNaa1MjM7FUYnGDQaRkkogTwuayuKyIVo",
  authDomain: "buddy-system-6a37c.firebaseapp.com",
  databaseURL: "https://buddy-system-6a37c-default-rtdb.firebaseio.com",
  projectId: "buddy-system-6a37c",
  storageBucket: "buddy-system-6a37c.appspot.com",
  messagingSenderId: "752282961689",
  appId: "1:752282961689:web:b1c446b12b66250fd66788",
  measurementId: "G-Y5NKPZ872T",
};

// Initialize Firebase.
const app = initializeApp(firebaseConfig);
// Initialize Realtime Database and get a reference to the service.
const database = getDatabase(app);

export default {
  name: "DashboardComponent",

  setup() {
    const store = useStore();
    const router = useRouter();

    auth.onAuthStateChanged((user) => {
      store.dispatch("fetchUser", user);
    });

    const user = computed(() => {
      return store.getters.user;
    });

    const signOut = async () => {
      await store.dispatch("logOut");
      router.push("/");
    };

    return { user, signOut };
  },
  data() {
    return {
      userVerified: false,
      canCalibrated: false,
      boardData: [],
      emailAdresses: [],
      boardsLength: 0,
      activeBoards: 0,
      amtOrPercentage: false,
      minEngagement: 1,
      percentage: 0,
      percentageActive: 0,
      signalSent: false,
    };
  },
  computed: {
    thresholdMet: function () {
      return this.activeBoards >= this.minEngagement;
    },
    percentActive: function () {
      return Math.floor((100 * this.activeBoards) / this.boardsLength);
    },
    percentageMet: function () {
      let totalPercentActive = (100 * this.activeBoards) / this.boardsLength;
      if (totalPercentActive >= this.percentage) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.checkEmailVerification();
    this.pollDevices();
    set(ref(database, `UsersData/${this.user.data.uid}/trigger/`), false);
  },
  methods: {
    signalSignal() {
      set(ref(database, `UsersData/${this.user.data.uid}/signal/`), true);
      this.signalSent = true;
    },
    triggerTrigger() {
      set(ref(database, `UsersData/${this.user.data.uid}/trigger/`), true);
      set(ref(database, `UsersData/${this.user.data.uid}/signal/`), false);
      this.signalSent = false;

      setTimeout(() => {
        set(ref(database, `UsersData/${this.user.data.uid}/trigger/`), false);
      }, 5000);

      this.emailAdresses.forEach((address) => {
        console.log(`email was sent to ${address}`);
      });
    },
    checkEmailVerification() {
      const user = auth.currentUser;
      if (user.emailVerified) {
        this.userVerified = true;
      }
    },
    resendVerificationEmail() {
      sendEmailVerification(auth.currentUser);
    },
    extractData(data) {
      this.boardsLength = 0;
      this.activeBoards = 0;
      this.emailAdresses = [];
      this.boardData = data;
      for (const key in data) {
        this.boardsLength++;
        if (data[key].action === "on") {
          this.activeBoards++;
          this.emailAdresses.push(data[key].email);
        }
      }
    },
    pollDevices() {
      const boardData = ref(database, `UsersData/${this.user.data.uid}/boards`);
      onValue(boardData, (snapshot) => {
        const data = snapshot.val();
        this.extractData(data);
      });
    },
  },
};
</script>
