<template>
  <div class="">
    <div class="row justify-content-center">
      <div class="" style=""><img src="@/assets/BuddySystem_logo.png" style="width: 90%; max-width: 200px" /></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeComponent",
  setup() {},
};
</script>
