<template>
  <nav class="navbar navbar-expand-md navbar-light navbar-laravel">
    <div class="container" style="justify-content: center;">
      <router-link to="/" class="navbar-brand" ><router-link to="/" ><img src="@/assets/buddy_system_wordmark.jpg" style="width: 100%; max-width: 500px" /></router-link></router-link>
    </div>
  </nav>
</template>

<script>
export default {
name: "NavBar",


};
</script>
