// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyDNaa1MjM7FUYnGDQaRkkogTwuayuKyIVo",
  authDomain: "buddy-system-6a37c.firebaseapp.com",
  databaseURL: "https://buddy-system-6a37c-default-rtdb.firebaseio.com",
  projectId: "buddy-system-6a37c",
  storageBucket: "buddy-system-6a37c.appspot.com",
  messagingSenderId: "752282961689",
  appId: "1:752282961689:web:b1c446b12b66250fd66788",
  measurementId: "G-Y5NKPZ872T"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

//initialize firebase auth
const auth = getAuth()

export { app, auth }
