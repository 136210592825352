<template>
  <div>
    <navbar v-if="this.$route.name !== 'home'"></navbar>
    <main v-if="this.$route.name === 'home'"
      class="py-4"
      style="
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <router-view></router-view>
    </main>

    <main v-if="this.$route.name !== 'home'"
      class="py-4"
    >
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
import navbar from "./components/Navbar";
export default {
  components: {
    navbar,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
