import { createStore } from "vuex";
import { auth } from "./firebaseConfig";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut, sendEmailVerification 
} from "firebase/auth";
import { getDatabase, ref, set } from "firebase/database";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDNaa1MjM7FUYnGDQaRkkogTwuayuKyIVo",
  authDomain: "buddy-system-6a37c.firebaseapp.com",
  databaseURL: "https://buddy-system-6a37c-default-rtdb.firebaseio.com",
  projectId: "buddy-system-6a37c",
  storageBucket: "buddy-system-6a37c.appspot.com",
  messagingSenderId: "752282961689",
  appId: "1:752282961689:web:b1c446b12b66250fd66788",
  measurementId: "G-Y5NKPZ872T",
};

// Initialize Firebase.
const app = initializeApp(firebaseConfig);
// Initialize Realtime Database and get a reference to the service.
const database = getDatabase(app);

const store = createStore({
  state: {
    user: {
      loggedIn: false,
      data: null,
    },
  },

  getters: {
    user(state) {
      return state.user;
    },
  },
  mutations: {
    SET_USER(state, payload) {
      state.user.data = payload;
    },
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
  },
  actions: {
    async register(context, { email, password, name }) {
      const response = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
        name
      );
      if (response) {
        context.commit("SET_USER", response.user);
        set(ref(database, `UsersData/${response.user.uid}`), [
          {
            name: response.user.displayName,
            email: response.user.email,
            id: response.user.uid,
          },
        ]);
        sendEmailVerification(auth.currentUser)
      } else {
        throw new Error("Unable to register user");
      }
    },

    async logIn(context, { email, password }) {
      const response = await signInWithEmailAndPassword(auth, email, password);
      if (response) {
        context.commit("SET_USER", response.user);
      } else {
        throw new Error("login failed");
      }
    },

    async logOut(context) {
      await signOut(auth);
      context.commit("SET_USER", null);
    },

    async fetchUser(context, user) {
      context.commit("SET_LOGGED_IN", user !== null);
      if (user) {
        context.commit("SET_USER", {
          displayName: user.displayName,
          email: user.email,
          uid: user.uid,
        });
      } else {
        context.commit("SET_USER", null);
      }
    },
  },
});

// export the store
export default store;
